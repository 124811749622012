import React, { ReactNode } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import Button, { ButtonProps } from '@material-ui/core/Button';
import styles from 'views/styles';

export interface IPanelContent {
  extraStyles?: any;
  className?: string;
  image?: {
    src: string;
    alt: string;
  };
  imageComponent?: ReactNode;
  renderIcon?: ReactNode;
  heading: string;
  text?: string | ReactNode;
  body?: ReactNode; // Similar to text, but without the <p>..</p> surrounding it
  buttons?: {
    text: string;
    href?: string;
    color?: ButtonProps['color'];
    variant?: ButtonProps['variant'];
    testId?: string;
    disabled?: boolean;
    onClick?: () => void;
  }[];
  customButtons?: ReactNode[];
  extraContent?: ReactNode;
  testId?: string;
}

const PanelContent = ({
  extraStyles = {},
  className,
  image,
  imageComponent,
  renderIcon,
  heading,
  text,
  body,
  buttons,
  testId,
  extraContent,
  customButtons,
}: IPanelContent) => (
  <div css={[style.root, extraStyles]} className={className} data-testid={testId}>
    {image && <img className='h-mb-xxs' css={style.image} src={image.src} alt={image.alt} />}
    {imageComponent && imageComponent}
    {typeof renderIcon === 'function' ? renderIcon() : renderIcon}
    <h3 className='h3'>{heading}</h3>
    {text && (
      <p className='p' css={style.text}>
        {text}
      </p>
    )}
    {body && <div className='body'>{body}</div>}
    {extraContent && extraContent}
    {buttons && (
      <div className="h-pt-xxs">
        {buttons.map((button) => (
          <Button
            css={style.button}
            component={button.href ? 'a' : 'button'}
            key={button.text}
            color={button.color || 'primary'}
            variant={button.variant || 'contained'}
            target={button.href && '_blank'}
            rel={button.href && 'noopener noreferrer'}
            href={button.href}
            onClick={button.onClick}
            disabled={button.disabled}
            data-testid={button.testId}
          >
            {button.text}
          </Button>
        ))}
      </div>
    )}
    {customButtons}
  </div>
);

const style = {
  root: css`
    text-align: center;
    padding: ${styles.spacing.md} ${styles.spacing.lg} ${styles.spacing.lg};
  `,
  text: css`
    max-width: 48rem;
    margin: ${styles.spacing.xxs} auto;
  `,
  button: css`
    margin: 0 ${styles.spacing.tiny} !important;
  `,
  image: css`
    max-width: 16rem;
  `,
};

export default PanelContent;
