import { useState, Fragment, useMemo } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Tooltip } from '@material-ui/core';
import styled from '@emotion/styled';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { ApiKeyForm } from 'models/ApiKey';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { btnMessages } from 'views/components/Button';
import FabButton from 'views/components/Button/FabButton';
import { useUser } from 'views/components/providers/UserProvider';
import { UserTeam } from 'models/User';
import useFormBuilder, { IManual } from 'components/FormBuilder';
import useCookie from 'hooks/useCookie';

interface IAPIKeyFormValues {
  name: string;
  accountNumber: string;
  team: UserTeam;
  isPrivate: boolean;
  isPublic: boolean;
  expiration: Date | string;
  neverExpires: boolean;
  scope: string[];
}

interface ICreateApiKeyDialog {
  createApiKey: (apiKeyData: Partial<ApiKeyForm>) => void;
  team?: number;
  isTeam?: boolean;
}

const messages = defineMessages({
  title: {
    id: 'account.apiKeys.createApiKey.type.label',
    defaultMessage: 'Create {type} API Key',
  },
  name: {
    id: 'account.apiKeys.createApiKey.name.label',
    defaultMessage: 'Name',
  },
  selectTeam: {
    id: 'account.apiKeys.createApiKey.selectTeam.label',
    defaultMessage: 'Select team',
  },
  mfaMessage: {
    id: 'account.apiKeys.createApiKey.account.mfaMessage',
    defaultMessage:
      'Pressing "Save" you\'ll be redirected to the PolySwarm authO login page to enter your two factor authentication ' +
      'to confirm the new API Key.',
  },
});

const ContentWrapper = styled.div`
  width: 100%;
  padding: ${styles.spacing.md};
`;

const Buttons = styled.div`
  margin-top: 1rem;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  *:first-of-type {
    margin-right: 5px !important;
  }

  *:last-of-type {
    margin-left: 5px !important;
  }
`;

const StyledButton = styled(Button)`
  flex: 1 1 auto;
`;

export const CreateApiKeyDialog = ({ createApiKey, team, isTeam }: ICreateApiKeyDialog) => {
  const intl = useIntl();
  const user = useUser();
  const { classes } = useStyles();
  const { show: showScopeField } = useCookie('apiKeysWithScope');
  const [errors, setErrors] = useState<string[]>([]);

  const formConfig = useMemo(
    () =>
      [
        {
          elementType: 'input',
          hideName: true,
          id: 'name',
          placeholder: 'Name',
          defaultValue: '',
        },
        {
          elementType: 'select',
          id: 'team',
          name: 'Team',
          placeholder: 'Select Team',
          defaultOptions: user.teams.map((item) => item),
          getOptionLabel: (
            option: { [key: string]: string | number },
            values: IAPIKeyFormValues
          ) => {
            return option.name;
          },
          bold: false,
          show: !!isTeam,
        },
        {
          name: 'Community',
          show: (data: IAPIKeyFormValues) => {
            return !!data.team && !!data.team.hasPrivateCommunity;
          },
          combine: [
            {
              elementType: 'checkbox',
              id: 'isPrivate',
              name: 'Private',
              defaultValue: true,
            },
            {
              elementType: 'checkbox',
              id: 'isPublic',
              name: 'Public',
              defaultValue: true,
            },
          ],
          dependency: ['team'],
        },
        {
          elementType: 'selectTag',
          id: 'scope',
          name: 'Scope',
          placeholder: 'Select wich scope to include',
          defaultOptions: ['scan', 'manual_analysis'],
          bold: false,
          hideName: true,
          show: showScopeField,
        },
        {
          name: 'Expiration',
          hideName: true,
          combine: [
            {
              elementType: { name: 'input', type: 'date' },
              hideName: true,
              name: 'Expiration Date',
              id: 'expiration',
              placeholder: 'Expiration Date',
              defaultValue: '',
            },
            {
              elementType: 'checkbox',
              name: 'Never Expire',
              id: 'neverExpires',
              defaultValue: false,
            },
          ],
        },
      ] as IManual[],
    [user.teams, isTeam, showScopeField]
  );

  const { FormComponent } = useFormBuilder(formConfig);

  const [open, setOpen] = useState(false);
  const _onClick = () => {
    setOpen(true);
  };

  const _onClose = () => {
    setOpen(false);
  };

  const _onSubmit = (values: IAPIKeyFormValues) => {
    const { neverExpires, isPrivate, ...formValues } = values;
    const errors: string[] = [];

    if (!neverExpires && !formValues.expiration) {
      errors.push('Expiration Date is required');
    }

    if (errors.length) {
      setErrors(errors);
      return;
    } else {
      setErrors([]);
    }

    const data = {
      name: formValues.name,
      expiration: neverExpires ? null : formValues.expiration,
      type: isTeam ? 'team' : 'user',
      community: values.team.hasPrivateCommunity
        ? values.isPrivate && values.isPublic
          ? undefined
          : !values.isPrivate
          ? 'public'
          : !values.isPublic
          ? 'private'
          : undefined
        : undefined,
    } as Record<string, any>;

    if (!!formValues?.team) {
      data.teamAccountNumber = formValues?.team.accountNumber;
    }

    if (showScopeField) {
      data.scope = {
        featureTags: formValues.scope,
      };
    }

    createApiKey(data);
  };

  return (
    <Fragment>
      <Tooltip title={`Add New ${team ? 'Team' : 'Personal'} API Key`} placement='top'>
        <div>
          <FabButton icon='plus' onClick={_onClick} testId='createApiKeyBtn' />
        </div>
      </Tooltip>
      <Dialog maxWidth='sm' open={open} onClose={_onClose}>
        <ContentWrapper>
          <div css={classes.header}>
            {intl.formatMessage(messages.title, { type: team ? 'Team' : 'Personal' })}
          </div>

          <FormComponent
            customClass={classes.form}
            filters={{}}
            onSubmit={_onSubmit}
            footerRender={({ dirtyFields, getValues }) => {
              const data = getValues(),
                hasPrivateCommunity = data?.team?.hasPrivateCommunity,
                hasCommunity = !!(data?.isPublic || data?.isPrivate);

              return (
                <div style={{ width: '100%' }}>
                  {errors.map((error, index) => (
                    <p key={index} css={classes.error}>
                      {error}
                    </p>
                  ))}
                  {user.mfaEnabled && (
                    <p css={classes.mfaMessage}>{intl.formatMessage(messages.mfaMessage)}</p>
                  )}
                  <Buttons>
                    <StyledButton
                      data-cy='submitBtn'
                      className='h-mt-xs'
                      color='primary'
                      variant='contained'
                      type='submit'
                      disabled={
                        !['name']
                          .concat(team ? ['team'] : [])
                          .every((key) => Object.keys(dirtyFields).includes(key)) ||
                        !['neverExpires', 'expiration'].some((key) =>
                          Object.keys(dirtyFields).includes(key)
                        ) ||
                        (hasPrivateCommunity && !hasCommunity)
                      }
                    >
                      <FormattedMessage {...btnMessages.save} />
                    </StyledButton>
                    <StyledButton
                      data-cy='cancelBtn'
                      className='h-mt-xs'
                      color='primary'
                      variant='outlined'
                      onClick={_onClose}
                    >
                      <FormattedMessage {...btnMessages.cancel} />
                    </StyledButton>
                  </Buttons>
                </div>
              );
            }}
          />
        </ContentWrapper>
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    expiresContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '3rem 0px 0px',
    },
    header: {
      textAlign: 'left',
      fontSize: '2.6rem',
      fontWeight: 'bold',
      paddingBottom: styles.spacing.xs,
    },
    mfaMessage: {
      textAlign: 'center',
      fontSize: '1.4rem',
    },
    neverExpiresLabel: {
      fontWeight: 'bold',
    },
    expiresCheckbox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minWidth: 'fit-content',
    },
    selectGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& span': {
        width: '100px',
      },
      paddingTop: '1rem',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
      '& div > span:first-of-type': {
        minWidth: '120px !important',
        width: '120px !important',
      },
    },
    error: {
      color: 'red',
      marginBottom: '2rem',
      textAlign: 'center',
      marginTop: '-2rem',
    },
  },
  light: {
    header: {
      color: styles.color.black,
    },
  },
  dark: {
    header: {
      color: styles.color.white,
    },
  },
});
